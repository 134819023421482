import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon as BaseIcon } from '@iconify/react';
import classNames from 'classnames';
import type { FC } from 'react';

type Props = {
  icon: IconDefinition | string;
  prefixIconClassName?: string;
};
const Icon: FC<Props> = ({ icon, prefixIconClassName }) => {
  if (typeof icon === 'string') {
    return (
      <BaseIcon
        icon={icon}
        className={classNames('text-xl text-white', prefixIconClassName)}
      />
    );
  }
  return (
    <FontAwesomeIcon className={prefixIconClassName} size="1x" icon={icon} />
  );
};

export default Icon;
