import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import { useRouter } from 'next/router';

import { queries } from '@/queries';
import { logout } from '@/services/profile';

import { useProfile } from './useProfile';

export const useLogout = (props?: UseMutationOptions<AxiosResponse<any>>) => {
  const queryClient = useQueryClient();
  const router = useRouter();
  const { data } = useProfile();
  return useMutation({
    mutationKey: ['logout'],
    mutationFn: logout,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queries.profile.me.queryKey });
      queryClient.removeQueries({ queryKey: queries.profile.me.queryKey });
      router.push(
        data?.accountType === 'CANDIDATE' ? '/jobs' : '/dashboard/auth/signin'
      );
    },
    ...props,
  });
};
